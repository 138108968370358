import type { LinkProps } from 'components/Link'

export type LayoutComponentCTA = {
    text: string
    url: LinkProps['url']
}

export enum LayoutComponentTypes {
    PROMO_CARD = 'Promo Card',
    ALERT = 'Alert',
    GREETING_CARD = 'Greeting Card',
}

export type LayoutComponentTrackingProps = {
    layoutComponentType: LayoutComponentTypes
    trackingId: string
    cta: LayoutComponentCTA | null
}
