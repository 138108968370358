import { useState } from 'react'

import styled, { css } from 'styled-components'

import type { AlertLayoutComponentVariant } from '__generated__/AlertLayoutComponent_alert.graphql'
import Icon from 'components/Icon'
import type { LayoutComponentCTA } from 'components/LayoutComponents/types'
import type { LinkProps } from 'components/Link'
import Link from 'components/Link'
import { MarkdownText } from 'components/Markdown'
import Box from 'components/Primitives/Box'
import type { BoxProps } from 'components/Primitives/Box'
import { fonts } from 'theme'
import type { ThemedComponent } from 'theme'
import type { RequiredPick } from 'types/RequiredPick'

type AlertProps = {
    variant: AlertLayoutComponentVariant
    text: string
    cta: LayoutComponentCTA | null
    trackCTAClick: () => void
    isDismissable?: boolean
}

const Alert = ({ text, cta, variant, trackCTAClick, isDismissable = false }: AlertProps) => {
    const [isDismissed, setIsDismissed] = useState(false)
    if (isDismissed) return null

    const handleClick = () => {
        setIsDismissed(true)
    }
    return (
        <AlertContainer
            variant={variant}
            role="alert"
            data-testid="alert"
            padding={['1rem', '0.75rem 1.25rem']}
        >
            <TextWrapper flexDirection={['column', 'row']} alignItems={['flex-start', 'center']}>
                <StyledBox variant={variant} marginRight={[0, '2rem']} marginBottom={['1rem', 0]}>
                    <MarkdownText input={text} />
                </StyledBox>
                {!!cta && (
                    <StyledLink url={cta.url} variant={variant} underline onClick={trackCTAClick}>
                        {cta.text}
                    </StyledLink>
                )}
            </TextWrapper>
            {isDismissable && (
                <Icon
                    icon="RiCloseFill"
                    onClick={handleClick}
                    title="Dismiss alert"
                    color={'neutral.0'}
                    marginLeft={['1rem', '1.25rem']}
                />
            )}
        </AlertContainer>
    )
}

type StyledBoxProps = ThemedComponent<BoxProps & RequiredPick<AlertProps, 'variant'>>
type StyledLinkProps = ThemedComponent<LinkProps & RequiredPick<AlertProps, 'variant'>>

const AlertContainer = styled(Box)<StyledBoxProps>`
    ${({ theme, variant }) => {
        switch (variant) {
            case 'BREAKING':
                return css`
                    background-color: ${theme.palette.destructive[50]};
                `
            case 'INFO':
                return css`
                    background-color: ${theme.palette.accent[50]};
                `
        }
    }}

    ${({ theme }) => css`
        border-radius: ${theme.radii.medium};
        font-size: ${theme.fontSize.XS};
    `}

    display: flex;
    width: 100%;
`

const StyledBox = styled(Box)<StyledBoxProps>`
    ${({ theme, variant }) => {
        switch (variant) {
            case 'BREAKING':
                return css`
                    & > ${Box}:first-child {
                        color: ${theme.palette.destructive[500]};
                    }
                `
            case 'INFO':
                return css`
                    & > ${Box}:first-child {
                        color: ${theme.palette.accent[500]};
                    }
                `
        }
    }}

    white-space: normal;

    // overriding MarkdownText styles
    & > ${Box}:first-child {
        line-height: 1.4;
        font-size: ${fonts.size.XS};
    }
`

const TextWrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const StyledLink = styled(Link)<StyledLinkProps>`
    white-space: nowrap;

    ${({ variant, theme }) => {
        switch (variant) {
            case 'BREAKING':
                return css`
                    color: ${theme.palette.destructive[500]};
                `
            case 'INFO':
            default:
                return css`
                    color: ${theme.palette.accent[500]};
                `
        }
    }}
`

export default Alert
