/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ContentPieceSection_contentPieceSelection = {
    readonly sectionStyle?: string | undefined;
    readonly cardStyle?: string | undefined;
    readonly title?: string | undefined;
    readonly subtitle?: string | undefined;
    readonly readMore?: {
        readonly text: string;
        readonly url: string;
    } | null | undefined;
    readonly contentPieceList?: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly " $fragmentRefs": FragmentRefs<"ContentPieceCardWrapper_contentPiece">;
            };
        }>;
    } | undefined;
    readonly " $refType": "ContentPieceSection_contentPieceSelection";
};
export type ContentPieceSection_contentPieceSelection$data = ContentPieceSection_contentPieceSelection;
export type ContentPieceSection_contentPieceSelection$key = {
    readonly " $data"?: ContentPieceSection_contentPieceSelection$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"ContentPieceSection_contentPieceSelection">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sectionStyle",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cardStyle",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subtitle",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ReadMoreField",
  "kind": "LinkedField",
  "name": "readMore",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "ContentPieceConnection",
  "kind": "LinkedField",
  "name": "contentPieceList",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContentPieceInterfaceNonNullEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ContentPieceCardWrapper_contentPiece"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentPieceSection_contentPieceSelection",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": (v6/*: any*/),
      "type": "DynamicContentSelection",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/)
      ],
      "type": "FlexibleContentSelection",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v6/*: any*/),
      "type": "ContentCollectionSelection",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v6/*: any*/),
      "type": "ContentTagSelection",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v6/*: any*/),
      "type": "GeographyTagSelection",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": (v6/*: any*/),
      "type": "AssetClassTagSelection",
      "abstractKey": null
    }
  ],
  "type": "SelectionRowBlock",
  "abstractKey": "__isSelectionRowBlock"
};
})();
(node as any).hash = '86f8bbd126f31f706806093e9a6733dd';
export default node;
