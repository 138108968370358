import type { ReactElement } from 'react'

import { graphql, useFragment } from 'react-relay'

import type { authorization_authorization$key } from '__generated__/authorization_authorization.graphql'
import type { authorization_content$key } from '__generated__/authorization_content.graphql'
import type { ContentPieceSection_contentPieceSelection$key } from '__generated__/ContentPieceSection_contentPieceSelection.graphql'
import ContentSection from 'modules/ContentSection'
import type { SECTION_STYLE } from 'modules/ContentSection/consts'

type ContentPieceSectionProps = {
    contentPieceSelection: ContentPieceSection_contentPieceSelection$key
    authorization: authorization_authorization$key
    contentAuthorization: authorization_content$key
    /**
     * Does this content piece section appear above the fold? https://en.wikipedia.org/wiki/Above_the_fold
     */
    isAboveTheFold?: boolean
}

const ContentPieceSection = (props: ContentPieceSectionProps): ReactElement | null => {
    const contentPieceSelection = useFragment(
        ContentPieceSectionFragment,
        props.contentPieceSelection,
    )

    const { sectionStyle, cardStyle, title, subtitle, readMore, contentPieceList } = contentPieceSelection

    if (!contentPieceList || !title) return null

    return (
        <ContentSection
            title={title}
            subtitle={subtitle}
            readMore={readMore}
            authorization={props.authorization}
            contentAuthorization={props.contentAuthorization}
            sectionStyle={sectionStyle as SECTION_STYLE}
            cardStyle={cardStyle}
            contentPieceList={contentPieceList}
            isAboveTheFold={props.isAboveTheFold}
        />
    )
}

const ContentPieceSectionFragment = graphql`
    fragment ContentPieceSection_contentPieceSelection on SelectionRowBlock {
        ... on DynamicContentSelection {
            sectionStyle
            cardStyle
            title
            subtitle
            readMore {
                text
                url
            }
            contentPieceList {
                edges {
                    node {
                        id
                        ...ContentPieceCardWrapper_contentPiece
                    }
                }
            }
        }
        ... on FlexibleContentSelection {
            cardStyle
            sectionStyle
            title
            subtitle
            readMore {
                text
                url
            }
            contentPieceList {
                edges {
                    node {
                        id
                        ...ContentPieceCardWrapper_contentPiece
                    }
                }
            }
        }
        ... on ContentCollectionSelection {
            sectionStyle
            cardStyle
            title
            subtitle
            readMore {
                text
                url
            }
            contentPieceList {
                edges {
                    node {
                        id
                        ...ContentPieceCardWrapper_contentPiece
                    }
                }
            }
        }
        # All section types below are soft-deprecated on chivas, and you can't create new ones
        ... on ContentTagSelection {
            sectionStyle
            cardStyle
            title
            subtitle
            readMore {
                text
                url
            }
            contentPieceList {
                edges {
                    node {
                        id
                        ...ContentPieceCardWrapper_contentPiece
                    }
                }
            }
        }
        ... on GeographyTagSelection {
            sectionStyle
            cardStyle
            title
            subtitle
            readMore {
                text
                url
            }
            contentPieceList {
                edges {
                    node {
                        id
                        ...ContentPieceCardWrapper_contentPiece
                    }
                }
            }
        }
        ... on AssetClassTagSelection {
            sectionStyle
            cardStyle
            title
            subtitle
            readMore {
                text
                url
            }
            contentPieceList {
                edges {
                    node {
                        id
                        ...ContentPieceCardWrapper_contentPiece
                    }
                }
            }
        }
    }
`

export default ContentPieceSection
