import Container from 'components/Container'
import Heading from 'components/Heading'
import { LegacyLink as Link } from 'components/Link'
import Paragraph from 'components/Paragraph'
import Box from 'components/Primitives/Box'
import SeoWrapper from 'layouts/SeoWrapper'
import { trackErrorPageSeen } from 'lib/analyticsApi'
import { routeMap } from 'shared/routes'

/*

    Displayed in the case of an error

    Note: for now this is the same as the 404 page.
    TODO we should perhaps create a different UI for the
    error case

*/

type Props = {
    message: string
    subtitle: string | React.ReactElement<any>
}

const Error = ({ message, subtitle }: Props) => {
    trackErrorPageSeen()

    return (
        <SeoWrapper seoProps={{ noindex: true }}>
            <Container data-cy="error-page">
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="calc(100vh - 200px)"
                >
                    <Box textAlign="center" maxWidth="600px">
                        {/* TODO replace this
                    <Image src={youBrokeTheInternet} alt="404" maxWidth="500px" width="100%" /> */}
                        <Heading maxWidth="600px" as="h1" fontSize={[1.5, 1.5, 2]} mt={3}>
                            {message}
                        </Heading>
                        <Box>{subtitle}</Box>
                    </Box>
                </Box>
            </Container>
        </SeoWrapper>
    )
}

const errorDefaultProps: Partial<Props> = {
    message: "Looks like you're lost 🕵️‍♀️. Try refreshing…",
    subtitle: (
        <Paragraph>
            Or <Link url={routeMap.home}>head to our homepage</Link> which is easy to find.
        </Paragraph>
    ),
}

Error.defaultProps = errorDefaultProps

export default Error
