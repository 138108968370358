/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LayoutComponentSection_viewer = {
    readonly features: ReadonlyArray<string>;
    readonly " $refType": "LayoutComponentSection_viewer";
};
export type LayoutComponentSection_viewer$data = LayoutComponentSection_viewer;
export type LayoutComponentSection_viewer$key = {
    readonly " $data"?: LayoutComponentSection_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"LayoutComponentSection_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LayoutComponentSection_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "features",
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = '419f46608abf6c2873220c26574a4b03';
export default node;
