/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AlertLayoutComponentVariant = "BREAKING" | "INFO" | "%future added value";
export type AlertLayoutComponent_alert = {
    readonly trackingId: string;
    readonly textMarkdown: string;
    readonly cta: {
        readonly text: string;
        readonly url: string;
    } | null;
    readonly variant: AlertLayoutComponentVariant;
    readonly " $refType": "AlertLayoutComponent_alert";
};
export type AlertLayoutComponent_alert$data = AlertLayoutComponent_alert;
export type AlertLayoutComponent_alert$key = {
    readonly " $data"?: AlertLayoutComponent_alert$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AlertLayoutComponent_alert">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AlertLayoutComponent_alert",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trackingId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "textMarkdown",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LayoutComponentCTA",
      "kind": "LinkedField",
      "name": "cta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "variant",
      "storageKey": null
    }
  ],
  "type": "AlertLayoutComponent",
  "abstractKey": null
};
(node as any).hash = 'e57776f001ca1df8bae4b6442f0e8a30';
export default node;
