/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BannerAdvertLayoutComponent_productAdvert = {
    readonly " $fragmentRefs": FragmentRefs<"BannerAdvert_activeProductAdvert">;
    readonly " $refType": "BannerAdvertLayoutComponent_productAdvert";
};
export type BannerAdvertLayoutComponent_productAdvert$data = BannerAdvertLayoutComponent_productAdvert;
export type BannerAdvertLayoutComponent_productAdvert$key = {
    readonly " $data"?: BannerAdvertLayoutComponent_productAdvert$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"BannerAdvertLayoutComponent_productAdvert">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BannerAdvertLayoutComponent_productAdvert",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BannerAdvert_activeProductAdvert"
    }
  ],
  "type": "ProductAdvert",
  "abstractKey": null
};
(node as any).hash = '8f9e0b273620e9a4f58304b51d44aea2';
export default node;
