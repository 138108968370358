import { useCallback, useEffect } from 'react'

import type { LayoutComponentTrackingProps } from 'components/LayoutComponents/types'
import { trackLayoutComponentCTAClicked, trackLayoutComponentShown } from 'lib/analyticsApi'

/**
 * Use this hook to track when a layout component is shown to a user
 */
export const useTrackLayoutComponentShown = (trackingProps: LayoutComponentTrackingProps) => {
    useEffect(() => {
        trackLayoutComponentShown(trackingProps)
    }, []) // Run only on mount
}

/**
 * Use this hook to return a function that can be used to track when a layout component is clicked
 */
export const useTrackLayoutComponentCTAClicked = (trackingProps: LayoutComponentTrackingProps) => {
    const trackClick = useCallback(() => {
        trackLayoutComponentCTAClicked(trackingProps)
    }, [trackingProps])

    return trackClick
}