/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type GreetingLayoutComponentTextColor = "BLACK" | "WHITE" | "%future added value";
export type GreetingLayoutComponentVariant = "HEAVY" | "LIGHT" | "MEDIUM" | "STRONG" | "TRANSPARENT" | "%future added value";
export type GreetingCardLayoutComponent_greetingCard = {
    readonly trackingId: string;
    readonly heading: string;
    readonly subheadingMarkdown: string;
    readonly cta: {
        readonly url: string;
        readonly text: string;
    } | null;
    readonly greetingVariant: GreetingLayoutComponentVariant;
    readonly image: {
        readonly " $fragmentRefs": FragmentRefs<"AccessibleImage_accessibleImage">;
    } | null;
    readonly backgroundImage: {
        readonly full: string;
    } | null;
    readonly greetingCardTextColor: GreetingLayoutComponentTextColor | null;
    readonly " $refType": "GreetingCardLayoutComponent_greetingCard";
};
export type GreetingCardLayoutComponent_greetingCard$data = GreetingCardLayoutComponent_greetingCard;
export type GreetingCardLayoutComponent_greetingCard$key = {
    readonly " $data"?: GreetingCardLayoutComponent_greetingCard$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"GreetingCardLayoutComponent_greetingCard">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GreetingCardLayoutComponent_greetingCard",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trackingId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heading",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subheadingMarkdown",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LayoutComponentCTA",
      "kind": "LinkedField",
      "name": "cta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "greetingVariant",
      "args": null,
      "kind": "ScalarField",
      "name": "variant",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessibleURLImage",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AccessibleImage_accessibleImage"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessibleURLImage",
      "kind": "LinkedField",
      "name": "backgroundImage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "full",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "greetingCardTextColor",
      "args": null,
      "kind": "ScalarField",
      "name": "textColor",
      "storageKey": null
    }
  ],
  "type": "GreetingLayoutComponent",
  "abstractKey": null
};
(node as any).hash = '1e7085d2aea3c1f7f4a244e34a507a42';
export default node;
