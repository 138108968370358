/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PromoCardLayoutComponentTextColor = "BLACK" | "WHITE" | "%future added value";
export type PromoCardLayoutComponentVariant = "HEAVY" | "LIGHT" | "MEDIUM" | "STRONG" | "TRANSPARENT" | "%future added value";
export type PromoCardLayoutComponent_promoCard = {
    readonly trackingId: string;
    readonly heading: string;
    readonly subheadingMarkdown: string;
    readonly cta: {
        readonly url: string;
        readonly text: string;
    } | null;
    readonly promoCardVariant: PromoCardLayoutComponentVariant;
    readonly image: {
        readonly " $fragmentRefs": FragmentRefs<"AccessibleImage_accessibleImage">;
    } | null;
    readonly backgroundImage: {
        readonly full: string;
    } | null;
    readonly promoCardTextColor: PromoCardLayoutComponentTextColor | null;
    readonly " $refType": "PromoCardLayoutComponent_promoCard";
};
export type PromoCardLayoutComponent_promoCard$data = PromoCardLayoutComponent_promoCard;
export type PromoCardLayoutComponent_promoCard$key = {
    readonly " $data"?: PromoCardLayoutComponent_promoCard$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"PromoCardLayoutComponent_promoCard">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PromoCardLayoutComponent_promoCard",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trackingId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heading",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subheadingMarkdown",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LayoutComponentCTA",
      "kind": "LinkedField",
      "name": "cta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "promoCardVariant",
      "args": null,
      "kind": "ScalarField",
      "name": "variant",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessibleURLImage",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AccessibleImage_accessibleImage"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccessibleURLImage",
      "kind": "LinkedField",
      "name": "backgroundImage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "full",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "promoCardTextColor",
      "args": null,
      "kind": "ScalarField",
      "name": "textColor",
      "storageKey": null
    }
  ],
  "type": "PromoCardLayoutComponent",
  "abstractKey": null
};
(node as any).hash = 'b3c67b3494111a1001f929b0a2ef4935';
export default node;
