import type { ReactElement } from 'react'

import styled from 'styled-components'

import Box from 'components/Primitives/Box'

type SectionProps = {
    children: ReactElement[]
}

/**
 * A generic component for displaying one or more layout components,
 * such as a promo card or alert.
 * Will wrap and extend as needed based on the screen size.
 * Make sure to check your UI is working correctly if using it
 * with different components.
 */
const LayoutComponentSection = (props: SectionProps) => {
    const { children } = props
    return (
        <StyledContainer flexDirection={['column', 'column', 'column', 'column', 'row']}>
            {children}
        </StyledContainer>
    )
}

const StyledContainer = styled(Box)`
    display: flex;
    padding: 0 0.5rem;
    gap: 2.5rem;
    margin-bottom: 1.5rem;
`

export default LayoutComponentSection
