/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type SharedLayoutBlockFragment_viewer = {
    readonly " $fragmentRefs": FragmentRefs<"LayoutComponentSection_viewer">;
    readonly " $refType": "SharedLayoutBlockFragment_viewer";
};
export type SharedLayoutBlockFragment_viewer$data = SharedLayoutBlockFragment_viewer;
export type SharedLayoutBlockFragment_viewer$key = {
    readonly " $data"?: SharedLayoutBlockFragment_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"SharedLayoutBlockFragment_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedLayoutBlockFragment_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LayoutComponentSection_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = 'e128f776f5a9e5265432d938d2f1a702';
export default node;
