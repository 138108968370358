/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LayoutComponentSection_section = {
    readonly layoutComponents: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly __typename: string;
                readonly " $fragmentRefs": FragmentRefs<"AlertLayoutComponent_alert" | "PromoCardLayoutComponent_promoCard" | "GreetingCardLayoutComponent_greetingCard" | "BannerAdvertLayoutComponent_productAdvert">;
            };
        }>;
    } | null;
    readonly " $refType": "LayoutComponentSection_section";
};
export type LayoutComponentSection_section$data = LayoutComponentSection_section;
export type LayoutComponentSection_section$key = {
    readonly " $data"?: LayoutComponentSection_section$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"LayoutComponentSection_section">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LayoutComponentSection_section",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LayoutComponentConnection",
      "kind": "LinkedField",
      "name": "layoutComponents",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "LayoutComponentNonNullEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AlertLayoutComponent_alert"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PromoCardLayoutComponent_promoCard"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "GreetingCardLayoutComponent_greetingCard"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "BannerAdvertLayoutComponent_productAdvert"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LayoutComponentSelection",
  "abstractKey": null
};
(node as any).hash = '61f86c17e864507a1dc067da2f820eb1';
export default node;
