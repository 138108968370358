import type { ReactElement, ReactNode } from 'react'

import type { ReactMarkdownProps } from 'react-markdown'
import Markdown from 'react-markdown'
import styled, { css } from 'styled-components'

import type {
    PromoCardLayoutComponentTextColor,
    PromoCardLayoutComponentVariant,
} from '__generated__/PromoCardLayoutComponent_promoCard.graphql'
import Button from 'components/Button'
import type { HeadingProps } from 'components/Heading'
import Heading from 'components/Heading'
import type { ServerImageProps } from 'components/Image/Relay/ServerImage'
import type { LayoutComponentCTA } from 'components/LayoutComponents/types'
import type { BoxProps } from 'components/Primitives/Box'
import Box from 'components/Primitives/Box'
import type { DefaultStyledProps } from 'components/styled'
import type { ThemedComponent, Theme } from 'theme'
import { fonts } from 'theme'
import type { RequiredPick } from 'types/RequiredPick'

type PromoCardImageType = ReactElement<ServerImageProps>

export type PromoCardProps = {
    variant: PromoCardLayoutComponentVariant
    backgroundImageURL: string | null
    textColor: PromoCardLayoutComponentTextColor | null
    heading: string
    subheadingMarkdown?: string
    cta: LayoutComponentCTA | null
    image: PromoCardImageType | null
    numComponentsInSection: number
    trackCTAClick?: () => void
}

const VERTICAL_STACKING_SUBHEADING_LENGTH_THRESHOLD = 200
const VERTICAL_STACKING_HEADING_LENGTH_THRESHOLD = 60

/**
 * A generic component for displaying a promo card with a
 * heading, subheading, CTA button, and an optional image.
 */
const PromoCard = (props: PromoCardProps) => {
    const {
        variant,
        backgroundImageURL,
        textColor,
        heading,
        subheadingMarkdown,
        cta,
        image,
        numComponentsInSection,
        trackCTAClick,
    } = props

    // if we have more than one component in a section, we want to stack images and text vertically
    // if we have a long subheading or heading, to avoid the text being too squashed
    const shouldStackVertically =
        numComponentsInSection > 1 &&
        ((subheadingMarkdown &&
            subheadingMarkdown.length > VERTICAL_STACKING_SUBHEADING_LENGTH_THRESHOLD) ||
            heading.length > VERTICAL_STACKING_HEADING_LENGTH_THRESHOLD)

    return (
        <PromoCardContainer variant={variant} $backgroundImageURL={backgroundImageURL}>
            {!!image && (
                <ImageContainer $shouldStackVertically={shouldStackVertically}>
                    {image}
                </ImageContainer>
            )}
            <PromoCardTextAndCTAContainer>
                <PromoCardTextContainer>
                    <HeadingContainer
                        variant={variant}
                        textColor={textColor}
                        fontSize={[fonts.size.M, null, fonts.size.XL]}
                    >
                        {heading}
                    </HeadingContainer>
                    {!!subheadingMarkdown && (
                        // We can't currently change the font styling e.g. size here because of the way
                        // the Text markdown component is set up
                        <SubHeading variant={variant} textColor={textColor}>
                            {subheadingMarkdown}
                        </SubHeading>
                    )}
                </PromoCardTextContainer>
                {!!cta && (
                    <Box my={1}>
                        <Button url={cta.url} fullWidth variant="secondary" onClick={trackCTAClick}>
                            {cta.text}
                        </Button>
                    </Box>
                )}
            </PromoCardTextAndCTAContainer>
        </PromoCardContainer>
    )
}

type StyledBoxProps = ThemedComponent<
    BoxProps & RequiredPick<PromoCardProps, 'variant'> & { $backgroundImageURL: string | null }
>

const PromoCardContainer = styled.div<StyledBoxProps>`
    ${({ variant, theme, $backgroundImageURL }) => {
        if ($backgroundImageURL) {
            return css`
                background-image: url(${$backgroundImageURL});
                background-size: cover;
            `
        }
        switch (variant) {
            case 'LIGHT':
                return css`
                    background: ${theme.gradients.light};
                `
            case 'MEDIUM':
                return css`
                    background: ${theme.palette.neutral[50]};
                `
            case 'STRONG':
                return css`
                    background: ${theme.gradients.strong};
                `
            case 'HEAVY':
                return css`
                    background: ${theme.palette.neutral[900]};
                `
            case 'TRANSPARENT':
                return css`
                    background: transparent;
                `
        }
    }}

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: ${({ theme }) => theme.radii.small};
    flex: 1;
`

// This is a transient prop as it is passed directly into a React node in `styled.div`
type ImageContainerProps = BoxProps & {
    $shouldStackVertically: boolean
}

const ImageContainer = styled.div<ImageContainerProps>`
    ${({ $shouldStackVertically }) => css`
        min-width: ${$shouldStackVertically ? '80%' : '50%'};
    `}
    margin: 1rem;
    display: flex;
    justify-content: center;
    position: relative;
    flex: 1;
`

const PromoCardTextAndCTAContainer = styled.div<DefaultStyledProps>`
    ${({ theme }) => css`
        margin: ${theme.space[2]};
        @media (min-width: ${theme.breakpoints[5]}) {
            align-items: flex-start;
        }
    `}
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
`

const PromoCardTextContainer = styled.div`
    align-items: left;
`

const getTextColor = ({
    variant,
    textColor,
    theme,
}: RequiredPick<PromoCardProps, 'variant' | 'textColor'> & { theme: Theme }) => {
    if (textColor) {
        switch (textColor) {
            case 'WHITE':
                return css`
                    color: ${theme.palette.neutral[0]};
                `
            case 'BLACK':
                return css`
                    color: ${theme.palette.neutral[900]};
                `
            default:
                break
        }
    }

    switch (variant) {
        case 'LIGHT':
        case 'MEDIUM':
            return css`
                color: ${theme.palette.neutral[900]};
            `
        case 'STRONG':
        case 'HEAVY':
            return css`
                color: ${theme.palette.neutral[0]};
            `
        default:
            break
    }
}

type ThemedHeading = ThemedComponent<HeadingProps> &
    RequiredPick<PromoCardProps, 'variant' | 'textColor'>
const HeadingContainer = styled(Heading)<ThemedHeading>`
    ${({ variant, theme, textColor }) => getTextColor({ variant, theme, textColor })}
    margin-bottom: 1rem;
    text-align: left;
`

type ThemedMarkdown = ThemedComponent<ReactMarkdownProps> &
    RequiredPick<PromoCardProps, 'variant' | 'textColor'> & { children: ReactNode }
const SubHeading = styled(Markdown)<ThemedMarkdown>`
    ${({ variant, theme, textColor }) => getTextColor({ variant, theme, textColor })}
    font-weight: ${fonts.weight.normal};
    padding: 0 0.15rem;
    line-height: 1.4;
    text-align: left;
`

export default PromoCard
