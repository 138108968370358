import type { ReactElement } from 'react'

import { graphql, useFragment } from 'react-relay'

import type { PromoCardLayoutComponent_promoCard$key } from '__generated__/PromoCardLayoutComponent_promoCard.graphql'
import AccessibleImage from 'components/Image/Relay/AccessibleImage'
import {
    useTrackLayoutComponentCTAClicked,
    useTrackLayoutComponentShown,
} from 'components/LayoutComponents/Hooks/tracking'
import PromoCardUI from 'components/LayoutComponents/PromoCard/PromoCard'
import { LayoutComponentTypes } from 'components/LayoutComponents/types'

type Props = {
    promoCard: PromoCardLayoutComponent_promoCard$key
    priorityImageLoad: boolean
    numComponentsInSection: number
}

const PromoCardLayoutComponent = (props: Props): ReactElement => {
    const { promoCard: promoCardKey, priorityImageLoad, numComponentsInSection } = props
    const promoCard = useFragment(PromoCardFragment, promoCardKey)
    const {
        heading,
        subheadingMarkdown,
        cta,
        image,
        promoCardVariant,
        trackingId,
        backgroundImage,
        promoCardTextColor,
    } = promoCard

    const trackingProps = {
        layoutComponentType: LayoutComponentTypes.PROMO_CARD,
        trackingId,
        cta,
    }

    useTrackLayoutComponentShown(trackingProps)

    const trackCTAClick = useTrackLayoutComponentCTAClicked(trackingProps)

    const imageComponent = !!image ? (
        <AccessibleImage
            priority={priorityImageLoad}
            accessibleImage={image}
            objectFit="contain"
            minHeight={200}
            fill
        />
    ) : null

    const backgroundImageURL = backgroundImage?.full ?? null

    return (
        <PromoCardUI
            heading={heading}
            subheadingMarkdown={subheadingMarkdown}
            cta={cta}
            variant={promoCardVariant}
            backgroundImageURL={backgroundImageURL}
            textColor={promoCardTextColor}
            image={imageComponent}
            numComponentsInSection={numComponentsInSection}
            trackCTAClick={trackCTAClick}
        />
    )
}

const PromoCardFragment = graphql`
    fragment PromoCardLayoutComponent_promoCard on PromoCardLayoutComponent {
        trackingId
        heading
        subheadingMarkdown
        cta {
            url
            text
        }
        promoCardVariant: variant
        image {
            ...AccessibleImage_accessibleImage
        }
        backgroundImage {
            full
        }
        promoCardTextColor: textColor
    }
`

export default PromoCardLayoutComponent
