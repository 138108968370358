import type { ReactElement } from 'react'

import { useFragment, graphql } from 'react-relay'

import type { AlertLayoutComponent_alert$key } from '__generated__/AlertLayoutComponent_alert.graphql'
import Alert from 'components/Alert/'
import {
    useTrackLayoutComponentCTAClicked,
    useTrackLayoutComponentShown,
} from 'components/LayoutComponents/Hooks/tracking'
import { LayoutComponentTypes } from 'components/LayoutComponents/types'

type AlertLayoutComponentProps = {
    // Fragment key for alert data
    alert: AlertLayoutComponent_alert$key
}

const AlertLayoutComponent = (props: AlertLayoutComponentProps): ReactElement | null => {
    const alert = useFragment(AlertLayoutComponentFragment, props.alert)

    const { textMarkdown, cta, variant, trackingId } = alert

    const trackingProps = {
        layoutComponentType: LayoutComponentTypes.ALERT,
        trackingId,
        cta,
    }

    useTrackLayoutComponentShown(trackingProps)

    const trackCTAClick = useTrackLayoutComponentCTAClicked(trackingProps)

    return (
        <Alert
            text={textMarkdown}
            cta={cta}
            variant={variant}
            trackCTAClick={trackCTAClick}
        />
    )
}

const AlertLayoutComponentFragment = graphql`
    fragment AlertLayoutComponent_alert on AlertLayoutComponent {
        trackingId
        textMarkdown
        cta {
            text
            url
        }
        variant
    }
`

export default AlertLayoutComponent
